/* override default styles from SessionPageContent.css */
/**
 * CSS for laying out and displaying blocks and boxes
 */
:root {

    /* Consistent paddings */

    /* shadows */

    /* All z-index values should be listed here */

    /* widths */
}
/**
 * CSS for colors
 */
/* stylelint-disable color-no-hex */
:root {
    /* TIER ONE - DESCRIPTIVE NAMES */

    /* Default / Text color + Light background / Background color + Light text */        /* dark orange */          /* brand blue */           /* red */        /* purple */         /* dark green */          /* gray */            /* white */        /* yellow */

    /* Highlight / Text color + Dark background / Background color + Dark text */                                /* orange */            /* light red #f0425f */      /* light purple #ce51e1 */                                 /* bright green */                                  /* bright blue - brand color */                                /* light yellow */

    /* Alerts */

    /* Additional blues */         /* dark blue #003366 */  /* medium blue #0066cc */ /* light blue #c7e3ff */  /* light blue #e5f2ff */

    /* Additional reds */  /* light red #fbd0d7 */  /* dark red #8E0B21 */

    /* Additional greens */                                 /* dark green */ /* light green #e5fff1 */

    /* Additional purples */    /* extra light purple #f3d3f8 */       /* very light purple #f3d3f840 */

    /* Additional oranges */    /* light orange #ffe8cc */

    /* Grayscale Colors */  /* Near black */ /* Dark gray #1d2830 */  /* Formerly graphite */       /* medium gray */ /* #b1b2b4 */        /* #d8d9da */  /* Near white #f2f2f3 */

    /* TIER TWO - SEMANTIC NAMES */

    /* Product Colors */

    /* Component Colors */

    /* Partner/Refi Lender Colors */

    /* First Republic palette */    /* forest green */        /* gold hacienda */        /* tan gray concrete */      /* dark gray matterhorn */

    /* Visualization palette */

    /* Marketing Page Colors */ /* #1d2830; */ /* #005cb9; *//* #0f1519; */ /* #1d2830; */ /* #5a666e; *//* #8a8c8f; */ /* #b1b2b4; */ /* #d7d8d9; *//* #f2f2f3; */ /* #e5f2ff; */ /* #eddef0; */
}
/* this section is deprecated - prefer to use device-specific names if possible */
/* device based size names */
/* iPhone 5 width: 20em */
/* iPhone 6 width: */
/* iPhone 5 height: 35.5em */
/* iPhone 6 plus height: 46em */
/* iPad width: 48em */
/* iPad height: 64em */
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 30em) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}
/**
 * CSS for text, titles, labels. word.
 */
/* Marketing Text Mixins */
/* basic setup for triangles in :before and :after pseudo-elements */
/* clears ul/ol defaults */
/* TODO Deprecated */
/* best pronounced with a texan drawl */
/* is this annoying yet? */
/* Marketing Button Mixins */
.assistance .highlighted {
    color: #005cb9;
}
#view-wrapper #view {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url("../../gradifui/assets/bg_redesign2020_hero.svg");

    padding-bottom: 8px;
}
#view-wrapper #view .session-page-logo {
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        background-image: url("../../gradifui/assets/Gradifi-Logo-Horiz-Color.svg");
        background-size: contain;
        width: 512px;
        height: 64px;
        margin: 40px auto;
        display: block;
    }
@media (max-width: 60em) {
#view-wrapper #view .session-page-logo {
        width: 440px;
    }
    }
@media (max-width: 40em) {
#view-wrapper #view .session-page-logo {
        margin: 32px auto 16px;
        width: 312px;
        height: 48px;
    }
    }
@media (max-width: 23em) {
#view-wrapper #view .session-page-logo {
        width: 256px;
    }
    }
#view-wrapper #view > .session-page-logo {
        display: none;
    }
#view-wrapper #view .site-content {
        color: black;
        -webkit-box-align: normal;
        -webkit-align-items: normal;
           -moz-box-align: normal;
            -ms-flex-align: normal;
                align-items: normal;
    }
#view-wrapper #view .session-page-logo,
    #view-wrapper #view .content {
        z-index: 1;
    }
#view-wrapper #view .content {
        padding-top: 0;
        padding-bottom: 0;
        margin: 64px 10% auto;
        min-height: 450px;
        max-width: none;
        width: 55%;
        background: #fff;
        -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.23);
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.23);
    }
main.content .not-found-header {
    margin-bottom: 8px;
}
.not-found-subheader {
    margin-bottom: 32px;
    text-align: center;
}
.assistance {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 500px;
    margin: auto;
}
.assistance .can-do-wrapper {
        width: 310px;
    }
.assistance .can-do-wrapper .can-do-list {
            width: 230px;
            padding-left: 16px;
        }
.assistance .can-do-wrapper .can-do-list .can-do-bullet-1 {
                display: -webkit-inline-box;
                display: -webkit-inline-flex;
                display: -moz-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
            }
.assistance .need-help-wrapper .need-help-header {
            margin-bottom: 8px;
        }
@media (max-width: 65em) {
    #view-wrapper #view .content {
        min-height: 450px;
        width: 75%;
    }

    .not-found-subheader {
        width: 70%;
        margin: 0 auto 24px;
    }

    .assistance .can-do-wrapper {
        width: 285px;
    }
}
@media (max-width: 40em) {
        #view-wrapper #view .content {
            min-height: 510px;
            width: 100%;
            margin-top: 16px;
            margin-left: 16px;
            margin-right: 16px;
        }

        #view-wrapper #view .session-page-logo {
            height: 40px;
            margin: 24px auto;
        }

    .not-found-subheader {
        width: 320px;
    }

    .assistance {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        width: auto;
    }

        .assistance .can-do-wrapper {
            width: 100%;
        }

            .assistance .can-do-wrapper .can-do-header {
                text-align: center;
            }

            .assistance .can-do-wrapper .can-do-list {
                font-size: 0.75rem;
                margin: 8px auto 24px;
                padding-left: 40px;
                padding-right: 24px;
                width: 250px;
            }

                .assistance .can-do-wrapper .can-do-list li {
                    margin-bottom: 4px;
                }

        .assistance .need-help-wrapper {
            text-align: center;
        }
}
@media (max-width: 23em) {
    #view-wrapper #view .content {
        margin: 8px;
    }

    .not-found-subheader {
        width: 265px;
    }
}
